import PropTypes from "prop-types";
function When({ condition, children }) {
  return !!condition && children;
}

When.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

When.defaultProps = {
  condition: false,
  children: <></>,
};

export default When;
